<template>
  <header
    id="portoll-header"
    class="bg-white relative z-40"
  >
    <div
      v-if="isOwner"
      class="flex items-center justify-end gap-4 p-4"
    >
      <button class="bg-slate-400 flex items-center gap-3 font-medium font-inter px-3 py-2 rounded text-sm text-white">
        <Icon
          name="material-symbols:visibility"
          class="h-5 w-5 text-white"
        />

        Preview Portoll
      </button>

      <button class="bg-indigo-500 flex items-center gap-3 font-medium font-inter px-3 py-2 rounded text-sm text-white">
        <Icon
          name="material-symbols:grain"
          class="h-5 w-5 text-white"
        />

        Publish Content
      </button>
    </div>
  </header>
</template>

<script lang="ts" setup>
  import { useAuthStore } from '~/stores/useAuthStore';

  const { isOwner } = useAuthStore();
</script>
